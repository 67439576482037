import axios from 'axios'
import mock from '../mock'

const data = {
  // knowledge base
  knowledgeBase: [
    {
      id: 1,
      category: 'sales-automation',
      img: require('@src/assets/images/book/no.1.jpg').default,
      title: 'Pedoman Teknis Monitoring dan Evaluasi Internal 2015',
      desc: 'Mengawasi proses pelaksanaan pendidikan tinggi.',
      deskripsi : 'Monev kegiatan pendidikan, penelitian, pengabdian masyarakat dan pengalaman Al Islam Kemuhammadiyahan dijalankan untuk mengetahui sejauhmana PTM telah menjalankan kegiatan sesuai dengan yang telah direncanakan dan untuk mengetahui apakah kegiatan tersebut dapat diselesaikan tepat waktu.',
      tahun : 2015,
      no_sk : ""
    },
    {
      id: 2,
      category: 'marketing-automation',
      img: require('@src/assets/images/book/no.2.jpg').default,
      title: 'Profil Lembaga Kemahasiswaan',
      desc: 'Sebelum terbentuk sebagai UKM awalnya adalah lembaga yang dimana dikelola oleh pimpinan Universitas Muhammadiyah.',
      deskripsi : 'Sebelum terbentuknya UKM, PS. Unismuh Makassar awalnya adalah lembaga yang dikelola oleh pimpinan Universitas Muhammadiyah Makassar dan awal pembentukannya pada rapat  pimpinan Universitas tahun 1998. Terbentuk sebagai UKM yakni pada 12 september 2014.',
      tahun :"",
      no_sk :""
    },
    {
      id: 3,
      category: 'api-questions',
      img: require('@src/assets/images/book/no.3.jpg').default,
      title: 'Peraturan Akademik',
      desc: 'Mewujudkan sinkronisasi peraturan akademik dengan berbagai peraturan di bidang pendidikan tinggi.',
      deskripsi :'Sistem pendidikan nasional harus mampu menjamin pemerataan kesempatan pendidikan,peningkatan mutu serta relevansi dan efisiensi manajemen pendidikan untuk menghadapi tantangan sesuai dengan tuntunan perubahan kehidupan lokal. Dalam mewujudkan sinkronisasi peraturan akademik universitas muhammadiyah makassar dengan berbagai peraturan di bidang pendidikan tinggi, maka dipandang perlu diadakan penyempurnaan beberapa ketentuan dalam peraturan akademik universitas muhammadiyah makassar tahun 2021.',
      tahun : '2020',
      no_sk : '276 TAHUN 1442/2021 M'
    },
    {
      id: 4,
      category: 'personalization',
      img: require('@src/assets/images/book/no.4.jpg').default,
      title: 'Peraturan Kepegawaian',
      desc: 'Terciptanya disiplin yang berbasis kinerja yang propesional pengelolaan kepegawaian.',
      deskripsi : 'Bahwa untuk tertib dan terarah pengelolaan kepegawaian. maka dipandang perlu menyempurnakan peraturan kepegawaian yang telah ada.',
      tahun : 2006,
      no_sk :'125 Tahun 1427/2006 M'
    },
    {
      id: 5,
      category: 'email-marketing',
      img: require('@src/assets/images/book/no.5.jpg').default,
      title: 'Panduan Monotoring dan Evaluasi Program Kerja Tahun 2016',
      desc: 'Monev dijalankan untuk mengetahui sejauhmana pelaksanaan program telah menjalankan kegiatan.',
      deskripsi :'Pelaksanaan monitoring dan evaluasi (Monev) program kerja Unisversitas Muhammadiyah Makassar merupakan bagian tak terpisahkan dari peningkatan budaya mutu secara terus menerus.Monitoring dan evaluasi terhadap program kerja, juga merupakan bentuk akuntabilitas dari unit kerja sebagai pelaksanaan baik yang terkait dengan aspek input, proses, maupun output kegiatan.',
      tahun :2016,
      no_sk :''
    },
    {
      id: 6,
      category: 'demand-generation',
      img: require('@src/assets/images/book/no.6.jpg').default,
      title: 'Pedoman Kebijakan Pengembangan Kurikulum',
      desc: 'Landasan hukum pendidikan tinggi dalam rangka peningkatan mutu pembelajaran.',
      deskripsi : 'Bahwa untuk kelancaran pengembangan kurikulum merdeka belajar-kampus merdeka,maka dipandang perlu menetapkan kebijakan pengembangan kurikulum.',
      tahun :2020,
      no_sk :''
    },
    {
      id: 7,
      category: 'demand-generation',
      img: require('@src/assets/images/book/no.7.jpg').default,
      title: 'Pedoman Pengembangan Kurikulum',
      desc: 'Kurikulum yang dikembangkan oleh program studi merupakan program untuk menghasilkan lulusan yang memiliki kualifikasi yang disepakati dalam KKNI.',
      deskripsi : 'Bahwa dalam kelancaran pelaksanaan pengembangan kurikulum merdeka belajar-kampus merdeka, maka dipandang perlu untuk menetapkan pedoman pengembangan kurikulum merdeka belajar-kampus merdeka.',
      tahun : 2020,
      no_sk :'112 Tahun 1441 H/2020 M'
    },
    {
      id: 8,
      category: 'demand-generation',
      img: require('@src/assets/images/book/no.8.jpg').default,
    title: 'Pedoman Pelaksanaan Kurikulum ',
    desc: 'Memberikan arahan tentang bagaimana melangsungkan berbagai bentuk kegiatan pembelajaran.',
    deskripsi : 'Secara umum isi buku pedoman perancangan pembelajaran terdiri dari beberapa pokok penting, diantaranya : 1) pedoman merumuskan CPMK dan sub-CPMK yang diturunkan dari CPL, 2) pedoman melakukan analisis pembelajaran, 3) pedoman menyusun rencana pembelajaran semester, dan 4) penilaian pembelajaran. Sebagai wujud implementasi kebijakan SN-Dikti dan kebijakan Merdeka-Belajar Kampus Merdeka.',
    tahun :2020,
    no_sk :'113 Tahun 1441 H/2020 M'
    },
    {
      id: 9,
      category: 'demand-generation',
      img: require('@src/assets/images/book/no.9.jpg').default,
      title: 'Pedoman Implementasi Program MBKM',
      desc: 'MBKM merupakan wujud pembelajaran di perguruan tinggi yang otonom dan flesibel.',
      deskripsi : 'Kampus merdeka merupakan salah satu kebijakan dari menteri pendidikan dan kebudayaan, Nadiem Makariem. Program utama yaitu : kemudahan pembukaan program studi baru,perubahan sistem akreditasi perguruan tinggi, kemudahan perguruan tinggi negeri menjadi PTN berbadan hukum, dan hak belajar tiga semester diluar program studi. Berbagai bentuk kegiatan belajar diluar perguruan tinggi, di antaranya melakukan magang/praktik kerja di industri atau tempat kerja lainnya.',
      tahun :2020,
      no_sk :'280 Tahun 1441 H/2020 M'

    },
    {
      id: 10,
      category: 'demand-generation',
      img: require('@src/assets/images/book/no.10.jpg').default,
      title: 'Pedoman Pembuatan dan Pelaksanaan Perjanjian Kerjasama ',
      desc: 'Kerjasama meliputi kegiatan-kegiatan yang berkaitan dengan Catur Dharma perguruan tinggi.'
    },
    {
      id: 11,
      category: 'demand-generation',
      img: require('@src/assets/images/book/no.11.jpg').default,
      title: 'Panduan Penyusun Kurikulum Pendidikan Tinggi',
      desc: 'Kurikulum merupakan nyawa dari dari suatu program pembelajaran sehingga memerlukan rancangan.'
    },
    {
      id: 12,
      category: 'demand-generation',
      img: require('@src/assets/images/book/no.12.jpg').default,
      title: 'Pedoman Pencairan, Penggunaan , dan Pelaporan Anggaran',
      desc: 'Mekanisme pencairan, penggunaan, dan pelaporan anggaran tersebut perlu diatur dalam peraturan rektor.'
    },
    {
      id: 13,
      category: 'demand-generation',
      img: require('@src/assets/images/book/no.13.jpg').default,
      title: 'Manual Mutu Sistem Penjaminan Mutu Internal (SPMI)',
      desc: 'Sistem Penjaminan Mutu Pendidikan tinggi berfungsi mengendalikan penyelenggaraan pendidikan tinggi.'
    },
    {
      id: 14,
      category: 'demand-generation',
      img: require('@src/assets/images/book/no.14.jpg').default,
      title: 'Prosedur Sistem Mutu Universitas Muhammadiyah Makassar Standar Operasional Prosedur (SOP) (BUKU IV)',
      desc: 'Meningkatkan sistem manajemen mutu dan organisasi yang mengacu pada suatu penyelenggara program pendidikan yang efektif.'
    },
    {
      id: 15,
      category: 'demand-generation',
      img: require('@src/assets/images/book/no.15.jpg').default,
      title: 'Manual Book Manajemen Mutu Program Studi 2015',
      desc: 'Manual Book merupakan acuan dalam pengelolaan dan penyelenggaraan akademik program studi.'
    },
    {
      id: 16,
      category: 'demand-generation',
      img: require('@src/assets/images/book/no.16.jpg').default,
      title: 'Manual Book Manajemen Mutu Program Studi 2018',
      desc: 'Menjadi Acuan dalam pengelolaan dan penyelenggaraan akademik.'
    },
   
    {
      id: 18,
      category: 'demand-generation',
      img: require('@src/assets/images/book/no.18.jpg').default,
      title: 'Prosedur Operasional Baku (POB) Implementasi Bentuk Kegiatan Pembelajaran (BKP)',
      desc: 'Kampus Merdeka adalah Hak belajar Tiga Semester di luar program studi.'
    },
    {
      id: 19,
      category: 'demand-generation',
      img: require('@src/assets/images/book/no.19.jpg').default,
      title: 'Prosedur Sistem Mutu Operasional (BUKU 2)',
      desc: 'Meningkatkan sistem manajemen mutu dan organisasi yang mengacu pada suatu penyelenggara program pendidikan.'
    },
    {
      id: 20,
      category: 'demand-generation',
      img: require('@src/assets/images/book/no.20.jpg').default,
      title: 'Peraturan Rektor Ekuivalensi Waktu Mengajar Penuh (EWMP) Bagi Dosen ',
      desc: 'EWMP adalah beban waktu tenaga pendidi/dosen perguruan tinggi.'
    },
    {
      id: 21,
      category: 'demand-generation',
      img: require('@src/assets/images/book/no.21.jpg').default,
      title: 'Pedoman Evaluasi Kurikulum',
      desc: 'Memberi arahan yang jelas tentang tujuan dan manfaat evaluasi kurikulum.'
    },
    {
      id: 22,
      category: 'demand-generation',
      img: require('@src/assets/images/book/no.22.jpg').default,
      title: 'Pedoman Kebijakan Pengembangan Kurikulum',
      desc: 'Menghasilkan lulusan yang memiliki kemampuan literasi baru.'
    },
    {
      id:23,
      category: 'demand-generation',
      img: require('@src/assets/images/book/no.23.jpg').default,
      title: 'Pedoman Pengembangan Kurikulum',
      desc: 'Kurikulum yang dikembangkan untuk menghasilkan lulusan yang memiliki kualifikasi yang disepakati KKNI.'
    },
    {
      id:24,
      category: 'demand-generation',
      img: require('@src/assets/images/book/no.24.jpg').default,
      title: 'PROSEDUR SISTEM MUTU Standar Operasional Prosedur (SOP) (BUKU III)',
      desc: 'Meningkatkan sistem manajemen mutu dan organisasi yang mengacu pada suatu penyelenggara program pendidikan.'
    },
    {
      id:25,
      category: 'demand-generation',
      img: require('@src/assets/images/book/no.25.jpg').default,
      title: 'PROSEDUR SISTEM MUTU (BUKU 1)',
      desc: 'Meningkatkan sistem manajemen mutu dan organisasi yang mengacu pada suatu penyelenggara program pendidikan.'
    },
    {
      id:26,
      category: 'demand-generation',
      img: require('@src/assets/images/book/no.26.jpg').default,
      title: 'Kurikulum Al Islam dan Kemuhammadiyahan (AIK)',
      desc: 'AIK dikembangkan untuk memberikan dasar-dasar etika mahasiswa sebagai paradigma pengembangan perannya dalam masyarakat.'
    },
    {
      id:27,
      category: 'demand-generation',
      img: require('@src/assets/images/book/no.27.jpg').default,
      title: 'Peraturan Akademik',
      desc: 'Mewujudkan sinkronisasi peraturan akademik dengan berbagai peraturan bidang pendidikan tinggi.'
    },
    {
      id:28,
      category: 'demand-generation',
      img: require('@src/assets/images/book/no.28.jpg').default,
      title: 'Kebijakan Akademik',
      desc: 'Membangun moralitas melalui aktualisasi nilai-nilai islam dalam lingkungan kampus.'
    },
    {
      id:29,
      category: 'demand-generation',
      img: require('@src/assets/images/book/no.29.jpg').default,
      title: 'Naskah Akademik Surat keterangan pendamping ijazah (SKPI) ',
      desc: 'SKPI berisi informasi tentang pencapaian akademik atau kualifikasi dari lulusan pendidikan tinggi bergelar.'
    },
    {
      id:30,
      category: 'demand-generation',
      img: require('@src/assets/images/book/no.30.jpg').default,
      title: 'Program Kerja ',
      desc: 'Universitas Muhammadiyah Makassar tahun 2003 mengalami tahapan transisi sejarah perkembangan berupa perubahan formasi kepemimpinan.'
    }
  ],
  categoryData: [
    {
      id: 0,
      title: 'Account Settings',
      icon: 'Settings',
      iconColor: 'text-primary',
      questions: [
        {
          id: 0,
          question: 'How Secure Is My Password?',
          slug: 'how-secure-is-my-password'
        },
        {
          id: 1,
          question: 'Can I Change My Username?',
          slug: 'can-i-change-my-username'
        },
        {
          id: 2,
          question: 'Where Can I Upload My Avatar?',
          slug: 'where-can-i-upload-my-avatar'
        },
        {
          id: 3,
          question: 'How Do I Change My Timezone?',
          slug: 'how-do-i-change-my-timezone'
        },
        {
          id: 4,
          question: 'How Do I Change My Password?',
          slug: 'how-do-i-change-my-password'
        }
      ]
    },
    {
      id: 1,
      title: 'API Questions',
      icon: 'Link',
      iconColor: 'text-success',
      questions: [
        {
          id: 0,
          question: 'Dessert halvah carrot cake sweet?',
          slug: 'dessert-halvah-carrot-cake-sweet'
        },
        {
          id: 1,
          question: 'Jelly beans bonbon marshmallow?',
          slug: 'jelly-beans-bonbon-marshmallow'
        },
        {
          id: 2,
          question: 'Marzipan chocolate gummi bears bonbon powder?',
          slug: 'marzipan-chocolate-gummi-bears-bonbon-powder'
        },
        {
          id: 3,
          question: 'Chupa chups lemon drops caramels?',
          slug: 'chupa-chups-lemon-drops-caramels'
        }
      ]
    },
    {
      id: 2,
      title: 'Billing',
      icon: 'FileText',
      iconColor: 'text-danger',
      questions: [
        {
          id: 0,
          question: 'Oat cake lemon drops sweet sweet?',
          slug: 'oat-cake-lemon-drops-sweet-sweet'
        },
        {
          id: 1,
          question: 'Cotton candy brownie ice cream wafer roll?',
          slug: 'cotton-candy-brownie-ice-cream-wafer-roll'
        },
        {
          id: 2,
          question: 'Chocolate bonbon cake sugar plum?',
          slug: 'chocolate-bonbon-cake-sugar-plum'
        },
        {
          id: 3,
          question: 'Cake fruitcake chupa chups?',
          slug: 'cake-fruitcake-chupa-chups'
        },
        {
          id: 4,
          question: 'Fruitcake bonbon dessert gingerbread powder?',
          slug: 'fruitcake-bonbon-dessert-gingerbread-powder'
        }
      ]
    },
    {
      id: 3,
      title: 'Copyright & Legal',
      icon: 'Lock',
      iconColor: 'text-warning',
      questions: [
        {
          id: 0,
          question: 'Marshmallow jelly beans oat cake?',
          slug: 'marshmallow-jelly-beans-oat-cake'
        },
        {
          id: 1,
          question: 'Cake ice cream jujubes cookie?',
          slug: 'cake-ice-cream-jujubes-cookie'
        },
        {
          id: 2,
          question: 'Sesame snaps tart cake pie chocolate?',
          slug: 'sesame-snaps-tart-cake-pie-chocolate'
        },
        {
          id: 3,
          question: 'Chocolate cake chocolate tootsi?',
          slug: 'chocolate-cake-chocolate-tootsi'
        },
        {
          id: 4,
          question: 'Caramels lemon drops tiramisu cake?',
          slug: 'caramels-lemon-drops-tiramisu-cake'
        },
        {
          id: 5,
          question: 'Brownie dessert gummies. Tiramisu bear claw apple?',
          slug: 'brownie-dessert-gummies-tiramisu-bear-claw-apple'
        }
      ]
    },
    {
      id: 4,
      title: 'Mobile Apps',
      icon: 'Smartphone',
      iconColor: 'text-info',
      questions: [
        {
          id: 0,
          question: 'Macaroon tootsie roll?',
          slug: 'macaroon-tootsie-roll'
        },
        {
          id: 1,
          question: 'Cheesecake sweet soufflé jelly tiramisu chocolate?',
          slug: 'cheesecake-sweet-soufflé-jelly-tiramisu-chocolate'
        },
        {
          id: 2,
          question: 'Carrot cake topping tiramisu oat?',
          slug: 'carrot-cake-topping-tiramisu-oat'
        },
        {
          id: 3,
          question: 'Ice cream soufflé marshmallow?',
          slug: 'ice-cream-soufflé-marshmallow'
        },
        {
          id: 4,
          question: 'Dragée liquorice dragée jelly beans?',
          slug: 'dragée-liquorice-dragée-jelly-beans'
        },
        {
          id: 5,
          question: 'Lemon drops gingerbread chupa chups tiramisu?',
          slug: 'lemon-drops-gingerbread-chupa-chups-tiramisu'
        }
      ]
    },
    {
      id: 5,
      title: 'Using KnowHow',
      icon: 'HelpCircle',
      iconColor: '',
      questions: [
        {
          id: 0,
          question: 'Brownie dessert gummies?',
          slug: 'brownie-dessert-gummies'
        },
        {
          id: 1,
          question: 'Cookie tiramisu lollipop?',
          slug: 'cookie-tiramisu-lollipop'
        },
        {
          id: 2,
          question: 'Bonbon sugar plum jelly-o?',
          slug: 'bonbon-sugar-plum-jelly-o'
        },
        {
          id: 3,
          question: 'Halvah chupa chups chupa chups?',
          slug: 'halvah-chupa-chups-chupa-chups'
        }
      ]
    }
  ],
  questionData: {
    title: 'Why Was My Developer Application Rejected?',
    lastUpdated: '10 Dec 2018',
    relatedQuestions: [
      {
        id: 0,
        question: 'Cake icing gummi bears?'
      },
      {
        id: 1,
        question: 'Jelly soufflé apple pie?'
      },
      {
        id: 2,
        question: 'Soufflé apple pie ice cream cotton?'
      },
      {
        id: 3,
        question: 'Powder wafer brownie?'
      },
      {
        id: 4,
        question: 'Toffee donut dragée cotton candy?'
      },
      {
        id: 5,
        question: 'Soufflé chupa chups chocolate bar?'
      }
    ],
    // ! Here we have used require for image source but in API it shall be URL of live image, this is just for demo purpose
    content: `<p>It has been said that astronomy is a humbling and character-building experience. There is perhaps no better demonstration of the folly of human conceits than this distant image of our tiny world. To me, it underscores our responsibility to deal more kindly with one another, and to preserve and cherish the pale blue dot, the only home we’ve ever known. The Earth is a very small stage in a vast cosmic arena. Think of the rivers of blood spilled by all those generals and emperors so that, in glory and triumph, they could become the momentary masters of a fraction of a dot. Think of the endless cruelties visited by the inhabitants of one corner of this pixel on the scarcely distinguishable inhabitants of some other corner, how frequent their misunderstandings, how eager they are to kill one another, how fervent their hatreds.</p><p class="ql-align-center"><img class="img-fluid w-100" src="${
      require('@src/assets/images/pages/kb-image.jpg').default
    }" /></p><h5>Houston</h5><p>that may have seemed like a very long final phase. The auto targeting was taking us right into a … crater, with a large number of big boulders and rocks … and it required …flying manually over the rock field to find a reasonably good area.</p><ul class="list-group list-group-circle ml-2 mt-2"><li class="list-group-item"><a class="text-body" href="/" rel="noopener noreferrer" >I am a stranger. I come in peace. Take me to your leader and there will be a massive reward for you in eternity.</a></li><li class="list-group-item"><a class="text-body" href="/" rel="noopener noreferrer" >It’s just mind-blowingly awesome. I apologize, and I wish I was more articulate, but it’s hard to be articulate when your mind’s blown—but in a very good way.</a></li><li class="list-group-item"><a class="text-body" href="/" rel="noopener noreferrer" >A good rule for rocket experimenters to follow is this</a></li></ul>`
  }
}

mock.onGet('/faq/data/knowledge_base').reply(() => [200, data.knowledgeBase])
mock.onGet('/faq/data/category').reply(() => [200, data.categoryData])
mock.onGet('/faq/data/question').reply(() => [200, data.questionData])

